.image-gallery {
  z-index: 0;
}

/* statická Lišta na navigování */
.statnav {
  background-color: #0084ff;
  width: 100%;
  overflow: hidden;
  position: fixed;
  z-index: 1;
  top: 0;
}

/* Linky v statické liště */
.statnav a {
float: left;
color: #f2f2f2;
text-align: center;
padding: 14px 16px;
text-decoration: none;
font-size: 20px;
height: 25px;
}

/* Change the color of links on hover */
.statnav a:hover {
background-color: #00a2ff;
color: black;
}

/* Add a color to the active/current link */
.statnav a.active {
background-color: #045faa;
color: white;
}

/* Right-aligned section inside the top navigation */
.statnav-right {
float: right;
}

@media screen and (max-width: 600px) {
  .statnav a:not(:first-child) {display: none;}
  .statnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .responsive {
    background-color: #0084ff;
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: 1;
    top: 0;
  }
  .responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .responsive a {
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 20px;
    height: 25px;
    float: none;
    display: block;
    text-align: left;
  }
}

.horizontalLine {
  width: 35px;
  height: 3px;
  background-color: black;
  margin: 3px 0;
}

.statnav .icon {
  display: none;
}

h1 {
  color: darkblue;
  text-align: center;
}

h2 {
  text-align: center;
  color: blue;
}

main {
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.9 );
  width: 50%;
  height: auto;
  padding: 30px 50px;
  margin: auto;

}


/* FotoGalerie na úvodní stránce */
.galery {
  background-color: #0084ff;
  width: 50%;
  height: 90%;
  margin: auto;
  line-height: 0px;
}

.fotoLoadbar {
  width: 100%;
  height: 5px;
}
.fotoLoadedbar {
  background-color: #333;
  height: 100%; 
  width: 100%;
}

.Downbar {
  width: 100%; 
}

.spodni {
  background-color: #333; 
  border-radius: 4px; 
  padding: 0.1%;
  flex-direction:row;
  display: flex;
  
}

.content {
  min-height: 75vh;
  margin-top: 80px;
  text-align: center;
}

.lotText {
  width: 75%;
  font-size: large;
}

.kontaktJmeno {
  color: #045faa;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.kontaktBlok {
  margin-left: auto;
  margin-right: auto;
}

#foto {
  margin:auto;
}

.sponsors {
  margin-top: auto;
  margin-bottom: auto;
  width:auto;
  margin-left: 5vh;
}

.sponsors a {
  margin-right: 1vh;
}

.navList {
  list-style-type: none;
  padding-left: 0em;
  margin-left: 0em;
}

.navList li {
  padding-left: 1em;
  text-indent: -1em;
}

.navList li:before {
  content: '>';
  padding-right: 5px;
  
  color: lightgray;
}
.navList li a {
  color: lightgray;
}

.navList li a:hover {
  color: lightslategray;
}

.h3inv {
  color:whitesmoke;
}

.navLinks {
  margin-left: auto;
  margin-right: 5vh;
}


.medailonky {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}


.medailonek { 
  margin: 10px;
  border-radius: 10px;
  width: 400px;
  display: flex;
  flex-direction: row;
  padding: 1%;
  background-color: lightblue;
}

.medailonek img {
  border-radius: 50%;
  height: 100px;
  align-self: center;
  width: 100px;
}

.medailonek .medailonekTextArea {
  margin: 10px;
}

.medailonek p {
  font-size: small;
}


.taborName {
  font-size: xxx-large;
  font-weight: 900;
  color: #0084ff;
}

li {
  text-align: left;
}




.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown #dropbtn {
  border: none;
  outline: none;
  padding: 14px 16px;
  margin: 0;
}

.dropdown-content {
  display: none;
  position: fixed; margin-top: 5vh;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 2;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}